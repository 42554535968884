import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, type InputNumberProps } from 'antd';
import type { FieldProps } from 'formik';
import { type CSSProperties, useMemo } from 'react';

import { isValidNumber } from 'shared/helpers';

// TODO: заставлять / запрещать arrayMode и delimiter в зависимости от типа данных
type RangeNumberInputFieldProps<
  T extends [number, number] | string | undefined = string,
> = FieldProps<T> &
  Omit<InputNumberProps, 'form' | 'stringMode' | 'onChange'> & {
    /** сохранять значения в виде массива [от, до] */
    min?: number;
    max?: number;
    fromPlaceholder?: string;
    toPlaceholder?: string;
    /** сохранять значения в виде массива [от, до] */
    arrayMode?: boolean;
    delimiter?: string;
    onChange?: (
      vals: [number | null | undefined, number | null | undefined],
    ) => void;
  } & (T extends [number, number] ? { arrayMode: true } : object);

const RangeNumberInputFieldBase = <
  T extends [number, number] | string | undefined,
>({
  field,
  form,
  min: minAllow = 14,
  max: maxAllow = Infinity,
  fromPlaceholder = 'от',
  toPlaceholder = 'до',
  arrayMode = false,
  delimiter = '..',
  onChange: theOnChange,
  ...props
}: RangeNumberInputFieldProps<T>) => {
  const [min, max] = useMemo(() => {
    const val = field.value;
    if (!val) return [];
    if (arrayMode) return val as [number, number];
    return (val as string).split(delimiter)?.map((el) => {
      const parsed = parseInt(el, 10);
      return Number.isNaN(parsed) ? undefined : parsed;
    });
  }, [arrayMode, delimiter, field.value]);

  const onChange = (
    vals: [number | null | undefined, number | null | undefined],
  ) => {
    theOnChange?.(vals);
    if (isValidNumber(vals[0]) || isValidNumber(vals[1])) {
      const val =
        arrayMode ?
          [vals[0], vals[1]].map((v) => (v === null ? undefined : v))
        : `${vals[0] ?? ''}${delimiter}${vals[1] ?? ''}`;
      form.setFieldValue(field.name, val);
    } else {
      form.setFieldValue(field.name, undefined);
    }
  };

  return (
    <div className="flex" style={{ alignItems: 'center' }}>
      <InputNumber
        onChange={(val) => onChange([val as number, max])}
        value={min}
        min={minAllow}
        max={Math.min(maxAllow, max ?? Infinity)}
        placeholder={fromPlaceholder}
        {...props}
      />
      <SwapRightOutlined />
      <InputNumber
        onChange={(val) => onChange([min, val as number])}
        value={max}
        min={Math.max(minAllow, min ?? 0)}
        max={maxAllow}
        placeholder={toPlaceholder}
        {...props}
      />
    </div>
  );
};

const RangeNumberInputField = (props: RangeNumberInputFieldProps) => (
  <RangeNumberInputFieldBase {...props} />
);

RangeNumberInputFieldBase.col = { width: '12em' } satisfies CSSProperties;
RangeNumberInputField.col = RangeNumberInputFieldBase.col;

RangeNumberInputField.infer = <
  V extends [number, number] | string | undefined,
>() =>
  RangeNumberInputFieldBase as React.ComponentType<
    RangeNumberInputFieldProps<V>
  >;

export default RangeNumberInputField;
