import { entries } from 'shared/helpers';
import { colors } from 'shared/theme/colors';

import type {
  DocGroup,
  DocStatus,
  DocType,
  DocTypeAdditionalPage,
  DocTypeCovidvax,
  DocTypeDrive,
  DocTypeMedical,
  DocTypePassport,
  MedicalBookStatus,
  PassportAddDoc,
} from './types';

export const DOC_GROUPS: Record<
  Exclude<DocGroup, 'additional_passport_pages'>,
  string
> = {
  passport: 'Паспорт',
  drive: 'Водительское удостоверение',
  medical: 'Медицинская книжка',
  covidvax: 'COVID-19',
};

export const DOC_GROUPS_FULL: Record<DocGroup, string> = {
  ...DOC_GROUPS,
  additional_passport_pages: 'Дополнительные страницы паспорта',
};

const PASSPORT_PAGES: Record<DocTypePassport, string> = {
  passport_main: 'Разворот с фотографией',
  passport_selfie: 'Ваше фото с паспортом',
  passport_registration: 'Разворот с регистрацией / Основной разворот УОП',
  passport_main2: 'Обратный разворот ID',
  passport_registration2: 'Обратный разворот УОП',
  legalization_main: 'Основной разворот ВНЖ/РПВ/МК',
  legalization_2: 'Обратный разворот МК',
  translation: 'Перевод',
  translation_2: 'Обратный разворот перевода',
};

const MEDICAL_PAGES: Record<DocTypeMedical, string> = {
  med1: 'Основной разворот медкнижки',
  med2: 'Отметки о профилактических прививках',
  med3: 'Заключение врача о допуске к работе по результатам медицинского обследования',
  med4: 'Результаты обследования на туберкулёз',
  med5: 'Результаты исследования на носительство возбудителей кишечных инфекционных заболеваний',
  med6: 'Результаты лабораторных исследований и осмотра дерматовенеролога',
  med7: 'Результаты исследования на гельминтозы',
  med8: 'Результаты исследования на носительство возбудителей дифтерии',
  med9: 'Профессиональная гигиеническая подготовка и аттестация',
};

const DRIVE_PAGES: Record<DocTypeDrive, string> = {
  drive_front: 'Лицевая сторона',
  drive_back: 'Обратная сторона',
};

const COV_PAGES: Record<DocTypeCovidvax, string> = {
  covidvax1: 'Страница 1',
  covidvax2: 'Страница 2',
  covidvax3: 'Страница 3',
  covidvax4: 'Страница 4',
};

const ADDITIONAL_PAGES: Record<DocTypeAdditionalPage, string> = {
  additional_page1: 'Первая дополнительная страница',
  additional_page2: 'Вторая дополнительная страница',
  additional_page3: 'Третья дополнительная страница',
};

export const DOC_PAGES = {
  passport: PASSPORT_PAGES,
  medical: MEDICAL_PAGES,
  drive: DRIVE_PAGES,
  covidvax: COV_PAGES,
  additional_passport_pages: ADDITIONAL_PAGES,
} satisfies Record<DocGroup, unknown>;

export const DOC_TYPES = entries(DOC_PAGES).reduce(
  (acc, [_, types]) => ({
    ...acc,
    ...types,
  }),
  {} as Record<DocType, string>,
);

export const DOC_STATUSES = {
  pending: 'В ожидании',
  confirmed: 'Подтвержден',
  refused: 'Отклонён',
  null: 'Не загружен',
} as const;

export const DOC_STATUSES_TAGS: Record<
  DocStatus,
  {
    title: string;
    color?: string;
  }
> = {
  pending: {
    title: DOC_STATUSES.pending,
    color: colors.orange,
  },
  confirmed: {
    title: DOC_STATUSES.confirmed,
  },
  refused: {
    title: DOC_STATUSES.refused,
    color: colors.red,
  },
  null: {
    title: DOC_STATUSES.null,
    color: colors.gray,
  },
};

export const MEDICAL_BOOK_STATUSES: Record<MedicalBookStatus, string> = {
  pending: 'На модерации',
  refused: 'Отклонено',
  confirmed: 'Подтверждено',
} as const;

export const ADDITIONAL_DOCUMENTS: Record<PassportAddDoc, string> = {
  residence_permit: 'ВНЖ',
  temporary_residence_permit: 'РВП',
  migration: 'Миграционная карта',
  other: 'Другое',
} as const;
