import { InfoCircleFilled } from '@ant-design/icons';
import useResizeObserver from '@react-hook/resize-observer';
import { Col, Popover } from 'antd';
import cn from 'classnames';
import type { FieldConfig, FieldProps, FormikValues } from 'formik';
import { Field, useFormikContext } from 'formik';
import type { CSSProperties, ComponentType, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';

import { getNestedKeys, getTextWidth, getValByNestedKey } from 'shared/helpers';
import type { NestedKeyOf, NestedObjectKeyType } from 'shared/types';
import Notice from 'shared/ui/notice';

import ErrorMessage from './error-message';
import { useFormContext } from './form-context';
import css from './form-field.module.scss';

type FormFieldPropsBase<
  Values extends FormikValues,
  Field extends NestedKeyOf<Values>,
> = Omit<
  FieldConfig<NestedObjectKeyType<Values, Field>>,
  'name' | 'component'
> & {
  name: Field;
  validate?: (
    value: NestedObjectKeyType<Values, Field>,
  ) => string | void | Promise<string | void>;
  value?: NestedObjectKeyType<Values, Field>;
  label?: ReactNode;
  notice?: string;
  className?: string;
  wrapperStyle?: CSSProperties;
  required?: boolean;
  markRequired?: boolean;
  enabledFields?: NestedKeyOf<Values>[];
  disabledFields?: NestedKeyOf<Values>[];
  disabled?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  hideError?: boolean;
  smallLabel?: boolean;
  clearLabel?: boolean;
  labelSuffix?: ReactNode;
  col?: boolean | CSSProperties;
  flex?: number;
  width?: number | string;
} & (NestedObjectKeyType<Values, Field> | undefined extends (
    NestedObjectKeyType<Values, Field>
  ) ?
    object
  : { markRequired: true } | { required: true } | { notRequired: true });

type FormFieldProps<
  Values extends FormikValues,
  Field extends NestedKeyOf<Values>,
  ComponentProps extends FieldProps<NestedObjectKeyType<Values, Field>, Values>,
> = FormFieldPropsBase<Values, Field> &
  Omit<
    ComponentProps,
    keyof FieldProps | keyof FormFieldPropsBase<Values, Field>
  > & {
    component: ComponentType<ComponentProps> & {
      col?: CSSProperties;
    };
  };

const DEFAULT_WRAPPER_STYLE = { width: '100%' };

// TODO: add nolabel (no form-group) and emptylabel variants
export const FormField = <
  Values extends FormikValues,
  Field extends NestedKeyOf<Values>,
  ComponentProps extends FieldProps<NestedObjectKeyType<Values, Field>>,
>({
  label,
  notice,
  className,
  wrapperStyle = DEFAULT_WRAPPER_STYLE,
  component,
  required,
  markRequired,
  name,
  enabledFields: theEnabledFields,
  disabledFields: theDisabledFields,
  disabled,
  noPadding,
  noMargin,
  hideError,
  smallLabel,
  clearLabel,
  labelSuffix,
  col,
  flex,
  width,
  ...props
}: FormFieldProps<Values, Field, ComponentProps>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState(0);
  useResizeObserver(ref, (entry) => {
    setContentWidth(entry.contentRect.width);
  });

  const { errors } = useFormikContext<Values>();
  const {
    alternativeValues: altVals,
    readOnly,
    disabledFields = theDisabledFields,
    enabledFields = theEnabledFields,
    noPadding: noPaddingFb,
    noMargin: noMarginFb,
    smallLabel: smallLabelFb,
    requiredFields,
    markRequiredFields,
  } = useFormContext<Values>();
  const altVal = getValByNestedKey(altVals ?? ({} as Values), name);
  const error = getValByNestedKey(errors, name);

  const altKeys = getNestedKeys(altVals ?? ({} as Values));

  const showAltVal = altKeys.includes(name);

  const status =
    // eslint-disable-next-line no-nested-ternary
    showAltVal ? 'warning'
    : error ? 'error'
    : (props as { status?: string }).status;

  const { suffix } = props as { suffix?: ReactNode };

  const fieldComponent = (
    <div
      className={cn(css.formGroup, className, {
        [css.formGroupNoPadding]: !!(noPadding ?? noPaddingFb),
        [css.formGroupNoMargin]: !!(noMargin ?? noMarginFb),
        [css.unlabeledField]:
          !label &&
          (props as { variant?: 'borderless' }).variant !== 'borderless',
      })}
      style={wrapperStyle}
      ref={ref}
    >
      {label ?
        <div
          className="flex nowrap"
          title={typeof label === 'string' ? label : undefined}
        >
          <label
            className={cn(
              smallLabel ?? smallLabelFb ? css.smallLabel : css.label,
              {
                [css.clearLabel]: clearLabel,
              },
            )}
            htmlFor={name as string}
          >
            {label}
          </label>
          {(required || markRequired || markRequiredFields?.includes(name)) && (
            <span
              style={{
                color: 'red',
                marginLeft: '.5ch',
                fontSize: smallLabel ?? smallLabelFb ? 12 : 'inherit',
              }}
            >
              *
            </span>
          )}
          {notice && <Notice notice={notice} indent="1ch" />}
          {labelSuffix && (
            <div style={{ marginLeft: '1ch' }}>{labelSuffix}</div>
          )}
        </div>
      : null}
      <Field
        id={name}
        component={component}
        label={label}
        required={required || requiredFields?.includes(name)}
        name={name}
        style={{ width: '100%' }}
        allowClear
        disabled={
          readOnly ||
          disabled ||
          disabledFields?.includes(name) ||
          (enabledFields && !enabledFields.includes(name))
        }
        validate={(e: NestedObjectKeyType<Values, Field>) => {
          if (required && (e === undefined || e === null))
            return 'Это поле обязательно';
          return props.validate?.(e);
        }}
        placeholder={!required && '—'}
        status={status}
        suffix={
          showAltVal ?
            <>
              {suffix}
              <Popover
                trigger="click"
                placement="bottomRight"
                arrowPointAtCenter
                overlayClassName={css.altPopover}
                content={
                  <Field
                    component={component}
                    disabled
                    placeholder={!required && '—'}
                    style={{ width: contentWidth }}
                    field={{ value: altVal }}
                    title={(altVal as string)?.toString()}
                    suffixIcon={null}
                    {...props}
                  />
                }
              >
                <InfoCircleFilled style={{ color: '#faad14' }} />
              </Popover>
            </>
          : suffix
        }
        {...props}
      />
      {/* TODO: handle other formik errors */}
      {!hideError && typeof error === 'string' ?
        <ErrorMessage error={error} />
      : null}
    </div>
  );

  const checkboxLabel = (props as { checkboxLabel?: string }).checkboxLabel;
  const placeholder = (props as { placeholder?: string }).placeholder;

  const colStyle = useMemo((): CSSProperties | undefined => {
    if (!col) return undefined;
    if (typeof col === 'object') return col;
    const labelWidth = typeof label === 'string' ? getTextWidth(label) + 24 : 0;
    const checkboxLabelWidth =
      checkboxLabel ? getTextWidth(checkboxLabel) + 24 : 0;
    const placeholderWidth = placeholder ? getTextWidth(placeholder) + 48 : 0;
    const defaultWidth = Math.max(
      labelWidth,
      checkboxLabelWidth,
      placeholderWidth,
      100,
    );
    if (flex === undefined && !width) {
      return (
        component.col ?? {
          minWidth: defaultWidth,
          flex: 1,
        }
      );
    }
    return {
      ...component.col,
      ...{ flex: flex === 0 ? undefined : flex ?? 1 },
      [(flex ?? component.col?.flex) === 0 ? 'width' : 'minWidth']:
        width ??
        component.col?.width ??
        component.col?.minWidth ??
        defaultWidth,
    };
  }, [col, component.col, label, flex, width, checkboxLabel, placeholder]);

  if (!colStyle) {
    return fieldComponent;
  }

  return <Col style={colStyle}>{fieldComponent}</Col>;
};

FormField.infer =
  <Values extends FormikValues>(
    inferProps?: (
      field: NestedKeyOf<Values>,
    ) => Partial<FormFieldPropsBase<Values, NestedKeyOf<Values>>>,
  ) =>
  <
    Field extends NestedKeyOf<Values>,
    ComponentProps extends FieldProps<NestedObjectKeyType<Values, Field>>,
  >(
    props: FormFieldProps<Values, Field, ComponentProps>,
  ) => {
    // eslint-disable-next-line react/destructuring-assignment
    const inferredProps = useMemo(() => inferProps?.(props.name), [props.name]);
    return (
      <FormField<Values, Field, ComponentProps> {...inferredProps} {...props} />
    );
  };

export default FormField;
