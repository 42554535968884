import type { InputNumberProps } from 'antd';
import { Input, InputNumber } from 'antd';
import type { TextAreaProps } from 'antd/lib/input';
import cn from 'classnames';
import type { FieldProps } from 'formik';
import React, {
  type CSSProperties,
  type Ref,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import InputBase, { type InputBaseProps } from './base';
import { parseNumber, phoneFormatter } from './helpers';
import RangeNumberInputField from './number-range-field';

interface InputFieldProps<V extends string | undefined = string>
  extends FieldProps<V>,
    Omit<InputBaseProps, 'value' | 'form'> {
  /** return true to prevent default logic */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | boolean;
  parser?: (value: string) => string;
  formatter?: (value: string) => string;
  clearEmpty?: boolean;
}

const InputFieldBase = <V extends string | undefined = string>({
  field,
  form,

  ...props
}: InputFieldProps<V>) => {
  const onChange = (value?: string) => {
    form.setFieldValue(field.name, value);
  };

  return <InputBase value={field.value} onChangeParsed={onChange} {...props} />;
};

const InputField = (props: InputFieldProps) => <InputFieldBase {...props} />;

InputField.idCol = { width: '17.5em' } satisfies CSSProperties;

InputField.infer = <V extends string | undefined>() =>
  InputFieldBase as React.ComponentType<InputFieldProps<V>>;

const InferredNumberString = InputField.infer<`${number}`>();

const NumberString = ({ parser, ...props }: InputFieldProps<`${number}`>) => {
  const parse = useCallback(
    (v: string) => {
      const parsed = v.toString().replace(/\D/g, '');
      return parser ? parser(parsed) : parsed;
    },
    [parser],
  );
  return <InferredNumberString {...props} parser={parse} />;
};

NumberString.phoneFormatter = phoneFormatter;

InputField.NumberString = NumberString;

export default InputField;

interface TextAreaFieldProps
  extends FieldProps<string>,
    Omit<TextAreaProps, 'value' | 'onChange' | 'form'> {
  parser?: (value: string) => string;
  formatter?: (value: string) => string;
  clearEmpty?: boolean;
}

InputField.TextArea = ({
  field,
  form,
  parser,
  formatter,
  clearEmpty,
  ...props
}: TextAreaFieldProps) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (clearEmpty && e.target.value === '') {
      form.setFieldValue(field.name, undefined);
    } else {
      form.setFieldValue(
        field.name,
        parser ? parser(e.target.value) : e.target.value,
      );
    }
  };

  return (
    <Input.TextArea
      defaultValue={field.value}
      {...props}
      value={formatter ? formatter(field.value) : field.value}
      onChange={onChange}
    />
  );
};

interface InputNumberFieldProps<
  T extends number | string = number,
  FieldT extends number | null | undefined = number | null | undefined,
> extends FieldProps<FieldT>,
    Omit<InputNumberProps<T>, 'value' | 'form' | 'stringMode'> {
  suffix?: React.ReactNode;
  clearEmpty?: boolean;
}

const InputFieldNumber = forwardRef(
  (
    {
      field,
      form,
      addonAfter,
      suffix,
      clearEmpty,
      onChange: theOnChange,
      ...props
    }: InputNumberFieldProps<number, number>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const onChange = (value: number | null) => {
      if (clearEmpty && value === null) {
        form.setFieldValue(field.name, undefined);
      } else {
        form.setFieldValue(field.name, value);
      }
      theOnChange?.(value);
    };

    return (
      <InputNumber
        value={field.value}
        {...props}
        ref={ref}
        addonAfter={
          addonAfter || suffix ?
            <>
              {addonAfter}
              {suffix ?
                <>
                  {addonAfter ?
                    <>&nbsp;&nbsp;</>
                  : null}
                  {suffix}
                </>
              : null}
            </>
          : null
        }
        onChange={onChange}
      />
    );
  },
);

interface InputNumberDynamicFieldProps<
  T extends number | string = number,
  FieldT extends number | null | undefined = number | null | undefined,
> extends Omit<InputNumberFieldProps<T, FieldT>, 'addonAfter' | 'suffix'> {
  widthOffset?: number;
}

const InputFieldNumberDynamic = ({
  widthOffset = 14,
  ...props
}: InputNumberDynamicFieldProps<number, number>) => {
  const widthRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [width, setWidth] = useState(0);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!widthRef.current) return;
    setWidth(widthRef.current.offsetWidth);
  }, [inputValue]);

  useEffect(() => {
    setTimeout(() => {
      if (!inputRef.current) return;
      setInputValue(inputRef.current.value);
    }, 0);
  }, [props.field.value]);

  return (
    <>
      <InputFieldNumber
        {...props}
        ref={inputRef}
        onKeyUp={(ev) => {
          setInputValue(inputRef.current?.value ?? '');
          props.onKeyUp?.(ev);
        }}
        onBlur={(ev) => {
          props.onBlur?.(ev);
          setTimeout(() => {
            setInputValue(inputRef.current?.value ?? '');
          }, 0);
        }}
        style={{
          ...props.style,
          width: width + widthOffset,
        }}
        controls={false}
        addonAfter={null}
      />
      <span
        className={cn('ant-input-number', props.className)}
        style={{
          ...props.style,
          width: 'fit-content',
          position: 'absolute',
          visibility: 'hidden',
        }}
        ref={widthRef}
      >
        {inputValue}
      </span>
    </>
  );
};

InputField.NumberDynamic = InputFieldNumberDynamic;

InputField.Number = InputFieldNumber;

const NumberStringMode = ({
  field,
  form,
  addonAfter,
  suffix,
  clearEmpty,
  ...props
}: InputNumberFieldProps<`${number}`, number>) => {
  const onChange = (value: `${number}` | null) => {
    const parsed = Number(value);
    if (value !== null && !Number.isNaN(parsed)) {
      form.setFieldValue(field.name, parsed);
    } else {
      form.setFieldValue(field.name, clearEmpty ? undefined : null);
    }
  };

  return (
    <InputNumber
      {...props}
      addonAfter={
        addonAfter || suffix ?
          <>
            {addonAfter}
            {suffix ?
              <>&nbsp;&nbsp;{suffix}</>
            : null}
          </>
        : null
      }
      stringMode
      value={
        Number.isNaN(Number(String(field.value))) ? null : (
          (String(field.value) as `${number}`)
        )
      }
      onChange={onChange}
    />
  );
};

NumberStringMode.parseNumber = parseNumber;

InputField.NumberStringMode = NumberStringMode;

InputField.NumberRange = RangeNumberInputField;
